import { css } from '@emotion/react'
import {
  ALL_QUOTE_EDITOR_TABS,
  exclusionsDimension,
  type LooselyTypedData,
  type QuoteEditorTabId,
} from '@orus.eu/dimensions'
import {
  Divider,
  LanguageProvider,
  LayoutTabbed,
  PersistentNotification,
  SaveablePage,
  Section,
  Tab,
  spacing,
  useAsyncCallback,
  useUiContext,
} from '@orus.eu/pharaoh'
import { useParams, useSearch } from '@tanstack/react-router'
import { Fragment, memo, useCallback, useRef, useState, type FormEvent, type ReactNode } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { noop } from '../../../../lib/noop'
import { usePermissions } from '../../../../lib/use-permissions'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { PreventUpdatingContractDialog } from '../../../organisms/prevent-updating-contract-dialog'
import { useModularNextEnabled } from '../../subscription-v2/modular-next-enabled'
import { SubscriptionBackofficeElementGroupBlock } from '../../subscription-v2/subscription-v2-element-group-block'
import { ExclusionsDimensionField } from './backoffice-quote-editor-v2/fields/exclusions-dimension-field'
import { QuoteEditorV2Header } from './backoffice-quote-editor-v2/quote-editor-v2-header'
import { QuoteEditorV2SidePanel } from './backoffice-quote-editor-v2/quote-editor-v2-side-panel'
import { useQuoteEditorState } from './backoffice-quote-editor-v2/quote-editor-v2-state'
import { getQuoteEditorUiTabs } from './backoffice-quote-editor-v2/sections/quote-editor-sections.util'
import { useRequiredDimensionsHighlights } from './use-required-dimensions-highlights'

const PendingSubscriptionPage = memo(function PendingSubscriptionPage(): ReactNode {
  const uiContext = useUiContext()
  const { subscriptionId, organization } = useParams({ strict: false })
  const { tab_id: tabId } = useSearch({ strict: false })
  assert(subscriptionId, 'missing subscriptionId url param')
  const { permissions, type } = usePermissions()
  const isPartner = type === 'partner'

  const { isLoading: isSubscriptionOrganizationTypeLoading, data: subscriptionOrganizationType } =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useQuery(subscriptionId)

  const { isLoading: isSubscriptionAccessibleProductsLoading } = trpcReact.subscriptions.getAccessibleProducts.useQuery(
    organization!,
    { enabled: !!organization && isPartner },
  )

  const { isLoading: isOrganizationTechnicalNameLoading, data: currentSubscriptionOrganization } =
    trpcReact.organizations.getOrganizationInfoBySubscriptionId.useQuery(subscriptionId)

  const {
    changes,
    setChanges,
    before,
    after,
    nonDimensionalDataAfter,
    reset,
    commit,
    commiting,
    stateChangeNotifications,
  } = useQuoteEditorState({ subscriptionId })

  const { nextEnabledByAllContributions, registerNextEnabledContribution } = useModularNextEnabled()

  /**
   * Compute the displayed state by displaying the freshest value available
   */
  const state = Object.assign(
    {},
    // Start with the complete state computed on the server
    after,
    // Overriden by uncommited changes to the inputs
    changes,
  )

  const quoteEditorUiTabs = getQuoteEditorUiTabs({
    state,
    permissions,
    isPartner,
    subscriptionOrganizationType,
    isContractSigned: !!nonDimensionalDataAfter?.contract,
    currentOrganizationTechnicalName: currentSubscriptionOrganization?.technicalName,
    currentOrganizationCanEditPartnerFees: currentSubscriptionOrganization?.canEditPartnerFees,
  })
  const tabs = [...quoteEditorUiTabs.entries()].map(([name, _]) => name)
  const [missingDimensionsPerTab, setMissingDimensionsPerTab] = useState<Map<QuoteEditorTabId, Set<string>>>(new Map())

  const cleanTabsMissingDimensions = useCallback(() => {
    setMissingDimensionsPerTab(new Map())
  }, [])

  const handleAddMissingDimension = useCallback(
    (tab: QuoteEditorTabId, dimensionName: string) => {
      if (!ALL_QUOTE_EDITOR_TABS[tab].hasWritableInputs) return

      const currentTabMissingDimensions = missingDimensionsPerTab.get(tab) ?? new Set<string>()
      if (currentTabMissingDimensions.has(dimensionName)) return

      currentTabMissingDimensions.add(dimensionName)
      setMissingDimensionsPerTab(new Map(missingDimensionsPerTab).set(tab, currentTabMissingDimensions))
    },
    [missingDimensionsPerTab],
  )

  const handleRemoveMissingDimension = useCallback(
    (tab: QuoteEditorTabId, dimensionName: string) => {
      if (!ALL_QUOTE_EDITOR_TABS[tab].hasWritableInputs) return

      const currentTabMissingDimensions = missingDimensionsPerTab.get(tab) ?? new Set<string>()
      if (!currentTabMissingDimensions.has(dimensionName)) return
      currentTabMissingDimensions.delete(dimensionName)
      setMissingDimensionsPerTab(new Map(missingDimensionsPerTab).set(tab, currentTabMissingDimensions))
    },
    [missingDimensionsPerTab],
  )

  const { toggleHighlightDimensions, highlightDimensionsNames, isHighlightDimensionsActive, requiredDimensionsNames } =
    useRequiredDimensionsHighlights({
      state,
      subscriptionId,
      cleanTabsMissingDimensions,
      nonDimensionalDataAfter,
    })

  const handleSubmit = useCallback(
    (_event?: FormEvent, changes?: LooselyTypedData) => {
      if (changes) setChanges(changes)
    },
    [setChanges],
  )

  const tabsBreadcrumbsLength = tabs.reduce(
    (previous, curr) => {
      previous[curr] = quoteEditorUiTabs.get(curr)!.size
      return previous
    },
    {} as Record<string, number>,
  )

  const setSubscriptionOwner = useAsyncCallback(
    async (newCustomerId: string) => {
      await trpc.subscriptions.setSubscriptionOwner.mutate({ subscriptionId, customerId: newCustomerId })
      setChanges({})
    },
    [setChanges, subscriptionId],
  )

  const shouldBypassBrowserConfirmationDialog = useRef(false)

  const bypassBrowserConfirmationDialog = useCallback(() => (shouldBypassBrowserConfirmationDialog.current = true), [])

  const hasUnsavedChanges = Object.keys(changes).length > 0

  const getTabVariant = useCallback(
    (tab: QuoteEditorTabId) => {
      const missingDimensions = missingDimensionsPerTab.get(tab)
      if (!missingDimensions || missingDimensions.size === 0) return 'neutral'
      return 'danger'
    },
    [missingDimensionsPerTab],
  )

  if (
    !before ||
    !nonDimensionalDataAfter ||
    (isPartner &&
      (isSubscriptionAccessibleProductsLoading ||
        isOrganizationTechnicalNameLoading ||
        isSubscriptionOrganizationTypeLoading))
  )
    return <GlobalLoadingState />

  const currentEmail = changes.email || before.email || undefined
  const currentPhone = changes.phone || before.phone || undefined

  const wrapperCss = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: filter 0.3s;
    display: flex;
    flex-direction: column;
  `

  return (
    <div
      className="nodash"
      css={
        commiting
          ? css`
              filter: opacity(0.5);
              pointer-events: none;
              user-select: none;
              ${wrapperCss}
            `
          : wrapperCss
      }
    >
      <LanguageProvider value={state.operatingZone ?? undefined}>
        <SaveablePage hasUnsavedChanges={hasUnsavedChanges}>
          <PreventUpdatingContractDialog stateChangeNotifications={stateChangeNotifications} />
          <LayoutTabbed
            header={
              <>
                <QuoteEditorV2Header
                  subscriptionId={subscriptionId}
                  changes={changes}
                  onReset={reset}
                  onValidate={commit}
                  nonDimensionalDataAfter={nonDimensionalDataAfter}
                  hasQuote={!!after?.quote}
                  currentEmail={currentEmail}
                  currentPhone={currentPhone}
                  nextEnabledByAllContributions={nextEnabledByAllContributions}
                  bypassBrowserConfirmationDialog={bypassBrowserConfirmationDialog}
                />
                <ExclusionsDimensionField valueDimension={exclusionsDimension} state={state} />
                {nonDimensionalDataAfter.contract && isPartner ? (
                  <div
                    css={css`
                      margin: ${spacing[60]} 0;
                      width: 100%;
                    `}
                  >
                    <PersistentNotification variant="warning" title="Contrat en cours">
                      Veuillez contacter le support Orus pour émettre un avenant sur ce contrat.
                    </PersistentNotification>
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
            sidePanel={
              <QuoteEditorV2SidePanel
                state={state}
                stateBefore={before}
                stateAfter={after}
                setChanges={setChanges}
                toggleHighlightDimensions={toggleHighlightDimensions}
                isHighlightDimensionsActive={isHighlightDimensionsActive}
                changes={changes}
                hasQuote={!!after?.quote}
                currentEmail={currentEmail}
                currentPhone={currentPhone}
                organization={organization!}
                subscriptionId={subscriptionId}
                nonDimensionalDataAfter={nonDimensionalDataAfter}
              />
            }
            activeTabId={tabId ?? tabs[0]}
            tabs={tabs.map((tab) => {
              return {
                isFullBleed: ALL_QUOTE_EDITOR_TABS[tab].isFullBleed,
                tab: (
                  <Tab tabId={tab} key={tab} variant={getTabVariant(tab)} search={{ tab_id: tab }}>
                    {ALL_QUOTE_EDITOR_TABS[tab].title}
                  </Tab>
                ),
                content: (
                  <>
                    {[...quoteEditorUiTabs.get(tab)!.entries()].map(([breadcrumb, sections], index) => {
                      return (
                        <Fragment key={breadcrumb}>
                          <Section title={{ title: breadcrumb === 'Conversation' ? undefined : breadcrumb }}>
                            {sections.map((section) => (
                              <SubscriptionBackofficeElementGroupBlock
                                key={section.id}
                                uiContext={uiContext}
                                context="backoffice"
                                customerId={nonDimensionalDataAfter.customerId}
                                contract={nonDimensionalDataAfter.contract}
                                group={section.bodyElements}
                                subscriptionId={subscriptionId}
                                stepId={undefined}
                                versionedSubscriptionId={`${subscriptionId}/0`}
                                localState={state}
                                updateLocalChanges={setChanges}
                                updateAndPersistLocalChanges={setChanges}
                                handleSubmit={handleSubmit}
                                registerNextEnabledContribution={registerNextEnabledContribution}
                                nextEnabled={false}
                                serverValidationIssue={null}
                                goBackToPreviousStep={noop}
                                goBackToStepRoot={noop}
                                goBackToBreadcrumbRootStep={noop}
                                synchronizing={false}
                                setSubscriptionOwner={setSubscriptionOwner}
                                changes={changes}
                                backofficeStepTitle={section.backofficeUi.title}
                                backofficeStepSubtitle={section.backofficeUi.subtitle}
                                requiredDimensionsNames={requiredDimensionsNames}
                                highlightDimensionsNames={highlightDimensionsNames}
                                addMissingDimension={(dimensionName: string) =>
                                  handleAddMissingDimension(tab, dimensionName)
                                }
                                removeMissingDimension={(dimensionName: string) =>
                                  handleRemoveMissingDimension(tab, dimensionName)
                                }
                                missingSubscriptionDimensionsNames={
                                  nonDimensionalDataAfter.missingSubscriptionDimensionsNames
                                }
                              />
                            ))}
                          </Section>
                          {index !== tabsBreadcrumbsLength[tab] - 1 && (
                            <Divider
                              orientation="horizontal"
                              css={css`
                                margin-top: ${spacing[60]};
                                margin-bottom: ${spacing[60]};
                              `}
                            />
                          )}
                        </Fragment>
                      )
                    })}
                  </>
                ),
              }
            })}
          />
        </SaveablePage>
      </LanguageProvider>
    </div>
  )
})
export default PendingSubscriptionPage
