import styled from '@emotion/styled'
import {
  Dialog,
  Divider,
  PersistentNotification,
  spacing,
  Tab,
  Tabbar,
  Text,
  useAsyncCallback,
  useEnqueueToast,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import type { useQuoteEditorState } from './quote-editor-v2-state'

type FinalizeSubscriptionDialogProps = {
  onClose: () => void
  subscriptionId: string
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  organization: string
}

export const FinalizeSubscriptionDialog = memo<FinalizeSubscriptionDialogProps>(
  function FinalizeSubscriptionDialog(props) {
    const { onClose, subscriptionId, stateAfter, organization } = props

    const [sendingSignQuoteEmail, setSendingSignQuoteEmail] = useState(false)
    const [activeTabId, setActiveTabId] = useState<string>('sign-client-by-email')
    const { enqueueToast } = useEnqueueToast()
    const navigateToPayment = useNavigateTo({
      to: '/partner/$organization/pending-subscriptions/$subscriptionId/payment',
      params: { subscriptionId, organization },
    })

    const partnerSendSubscriptionToSignEmailMutation =
      trpcReact.subscriptions.partnerSendSubscriptionToSignEmail.useMutation()

    const brokerSendSubscriptionToSignEmail = useAsyncCallback(async () => {
      setSendingSignQuoteEmail(true)
      await partnerSendSubscriptionToSignEmailMutation.mutateAsync(subscriptionId)
      enqueueToast('Email de signature envoyé', { variant: 'success' })
      setSendingSignQuoteEmail(false)
      onClose()
    }, [subscriptionId, enqueueToast, partnerSendSubscriptionToSignEmailMutation, onClose])

    const handleSubmit = useCallback(() => {
      if (activeTabId === 'sign-client-by-email') {
        brokerSendSubscriptionToSignEmail()
      }
      if (activeTabId === 'finalize-by-phone') {
        navigateToPayment()
      }
    }, [activeTabId, brokerSendSubscriptionToSignEmail, navigateToPayment])

    if (!stateAfter) {
      return null
    }

    return (
      <Dialog
        title="Finaliser la souscription"
        onClose={onClose}
        style="base"
        size="medium"
        primaryActionLabel={activeTabId === 'sign-client-by-email' ? 'Envoyer' : 'Confirmer'}
        primaryActionDisabled={!!sendingSignQuoteEmail}
        primaryActionLoading={sendingSignQuoteEmail}
        onPrimaryAction={handleSubmit}
        secondaryActionLabel="Annuler"
        onSecondaryAction={onClose}
        tabbar={
          <StyledTabbarWrapper>
            <Tabbar
              size="medium"
              activeTabId={activeTabId}
              onClick={(tabId) => {
                setActiveTabId(tabId)
              }}
            >
              {Object.entries(TABS).map(([tabId, tabLabel]) => (
                <Tab key={tabId} tabId={tabId}>
                  {tabLabel}
                </Tab>
              ))}
            </Tabbar>
            <Divider orientation="horizontal" />
          </StyledTabbarWrapper>
        }
      >
        <div role="tabpanel">
          {activeTabId === 'sign-client-by-email' ? (
            <StyledTabContent>
              <Text variant="body2">
                Un email sera automatiquement envoyé au client, lui permettant de finaliser sa souscription de manière
                autonome. Il pourra ainsi signer électroniquement le contrat.
              </Text>
            </StyledTabContent>
          ) : null}
          {activeTabId === 'finalize-by-phone' ? (
            <StyledTabContent>
              <PersistentNotification variant="warning" icon="triangle-exclamation-solid">
                Attention, aucune signature ne sera demandé, assurez-vous que le consentement du client est clairement
                établi avant de finaliser en direct !
              </PersistentNotification>
              <Text variant="body2">
                Finalisez et signez directement la souscription client, incluant son moyen de paiement. Aucune action ne
                sera requise de la part du client.
              </Text>
            </StyledTabContent>
          ) : null}
        </div>
      </Dialog>
    )
  },
)

const TABS = {
  'sign-client-by-email': 'Signature client par email',
  'finalize-by-phone': 'Finaliser en direct',
} as const

const StyledTabbarWrapper = styled.div`
  margin: 0 ${spacing[60]};
`

const StyledTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
  margin: ${spacing[70]} ${spacing[5]} ${spacing[5]};
`
